<template>
  <v-tabs v-model="tab" vertical>
    <v-tab key="generalTab">
      <v-icon left>
        mdi-information
      </v-icon>
      Allgemein
    </v-tab>
    <v-tab key="totalListTab">
      <v-icon left>
        mdi-format-list-bulleted
      </v-icon>
      Liste aller Einträge
    </v-tab>

    <v-tab-item key="generalTab" class="mx-4 mt-3 ">
      <v-card flat>
        <v-btn
          color="primary"
          small
          @click="refreshGeneralData()"
          :disabled="userCountsLoading"
        >
          <v-icon> mdi-refresh </v-icon>
          Aktualisieren
        </v-btn>

        <v-row no-gutters class="mt-3">
          <TelemetryCard
            title="Benutzer"
            subtitle="24h / Woche / Monat"
            :body="formattedUserCounts"
            :loading="userCountsLoading"
          />
          <TelemetryCard
            title="App-Zugriffe"
            subtitle="24h / Woche / Monat"
            :body="formattedAccessCounts"
            :loading="accessCountsLoading"
            class="ml-3"
          />
          <TelemetryCard
            title="Fehlgeschl. Logins"
            subtitle="24h / Woche / Monat"
            :body="formattedFailedAuthCounts"
            :loading="failedAuthCountsLoading"
            class="ml-3"
          />
        </v-row>

        <div class="mt-8 text-h6">Zugriffe per App</div>
        <v-card
          flat
          :loading="appAccessCountsLoading"
          class="mt-1"
          max-height="25vh"
          style="overflow-y: scroll;"
        >
          <v-simple-table dense>
            <thead>
              <tr>
                <th>App</th>
                <th>Lizensiert</th>
                <th>letze 24h</th>
                <th>vergangene Woche</th>
                <th>vergangenes Monat</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="app in appAccessCounts" :key="app.appName">
                <td>{{ app.appName }}</td>
                <td>{{ app.licensed }}</td>
                <td>{{ app.counts.lastDay }}</td>
                <td>{{ app.counts.lastWeek }}</td>
                <td>{{ app.counts.lastMonth }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-card>
    </v-tab-item>

    <v-tab-item key="totalListTab" class="mx-4 mt-3">
      <v-card flat>
        <v-row no-gutters class="d-inline-flex" align="center">
          <v-checkbox v-model="filterUser" label="Nach Benutzer filtern" />
          <v-select
            label="Benutzer"
            :disabled="false == filterUser"
            :items="telemetryUsers"
            v-model="selectedTelemtryUser"
            class="ml-4"
          />

          <v-btn
            fab
            x-small
            class="ml-2"
            :disabled="false == filterUser"
            @click="refreshTelemetryUsers()"
          >
            <v-icon dark>
              mdi-refresh
            </v-icon>
          </v-btn>

          <v-btn
            rounded
            class="ml-14"
            color="primary"
            :disabled="totalListLoadButtonDisabled"
            @click="loadTotalList()"
          >
            &nbsp;Laden&nbsp;
          </v-btn>
        </v-row>

        <v-data-table
          :headers="totalListHeaders"
          :items="totalListItems"
          :loading="totalListLoading"
          dense
        >
        </v-data-table>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import TelemetryCard from './TelemetryCard.vue'
import devService from '@/services/devPortal/devService.js'

export default {
  components: {
    TelemetryCard
  },
  data: () => ({
    tab: null,

    // General props
    userCountsLoading: false,
    userCounts: {
      lastDay: '-',
      lastMonth: '-',
      lastWeek: '-'
    },
    accessCountsLoading: false,
    accessCounts: {
      lastDay: '-',
      lastMonth: '-',
      lastWeek: '-'
    },
    failedAuthCountsLoading: false,
    failedAuthCounts: {
      lastDay: '-',
      lastMonth: '-',
      lastWeek: '-'
    },
    appAccessCountsLoading: false,
    appAccessCounts: [],

    // Total list props
    filterUser: false,
    telemetryUsers: [],
    selectedTelemtryUser: null,

    totalListLoading: false,
    totalListHeaders: [
      {
        text: 'ID',
        value: 'id'
      },
      {
        text: 'Username',
        value: 'username'
      },
      {
        text: 'Timestamp',
        value: 'timestamp'
      },
      {
        text: 'Sektion',
        value: 'section'
      },
      {
        text: 'Aktion',
        value: 'action'
      },
      {
        text: 'Details',
        value: 'description'
      }
    ],
    totalListItems: []
  }),
  computed: {
    formattedUserCounts() {
      return (
        this.userCounts.lastDay +
        ' / ' +
        this.userCounts.lastWeek +
        ' / ' +
        this.userCounts.lastMonth
      )
    },
    formattedAccessCounts() {
      return (
        this.accessCounts.lastDay +
        ' / ' +
        this.accessCounts.lastWeek +
        ' / ' +
        this.accessCounts.lastMonth
      )
    },
    formattedFailedAuthCounts() {
      return (
        this.failedAuthCounts.lastDay +
        ' / ' +
        this.failedAuthCounts.lastWeek +
        ' / ' +
        this.failedAuthCounts.lastMonth
      )
    },
    totalListLoadButtonDisabled() {
      if (this.filterUser) return this.selectedTelemtryUser == null
      return false
    }
  },
  methods: {
    loadGeneralData() {
      this.userCountsLoading = true
      this.accessCountsLoading = true
      this.failedAuthCountsLoading = true
      this.appAccessCountsLoading = true

      devService
        .getUserCounts()
        .then(data => {
          this.userCounts = data
        })
        .finally(() => {
          this.userCountsLoading = false
        })

      devService
        .getAccessCounts()
        .then(data => {
          this.accessCounts = data
        })
        .finally(() => {
          this.accessCountsLoading = false
        })

      devService
        .getFailedAuthCounts()
        .then(data => {
          this.failedAuthCounts = data
        })
        .finally(() => {
          this.failedAuthCountsLoading = false
        })

      devService
        .getAppAccessCounts()
        .then(data => {
          this.appAccessCounts = data
        })
        .finally(() => {
          this.appAccessCountsLoading = false
        })
    },

    refreshGeneralData() {
      this.loadGeneralData()
    },

    loadTotalListMasterData() {
      devService.getUsers().then(data => {
        this.telemetryUsers = data
      })
    },

    refreshTelemetryUsers() {
      this.loadTotalListMasterData()
    },

    loadTotalList() {
      this.totalListLoading = true
      devService
        .getTotalList(
          this.filterUser == true ? this.selectedTelemtryUser : null
        )
        .then(data => {
          this.totalListItems = data
        })
        .finally(() => {
          this.totalListLoading = false
        })
    }
  },
  mounted() {
    this.loadGeneralData()
    this.loadTotalListMasterData()
  }
}
</script>
