<template>
  <v-container class="d-flex flex-column align-stretch fill-height">
    <SiteHeaderComponent header="DEV-Portal" />

    <v-card class="my-8 flex-grow-1" :loading="loading">
      <v-tabs v-model="tab">
        <v-tab key="backendTab">Backend-Information</v-tab>
        <v-tab key="jwtTab">JWT-Information</v-tab>
        <v-tab key="clientTab">Client-Informationen</v-tab>
        <v-tab key="telemetryTab">Telemetrie</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="backendTab">
          <v-data-table
            :headers="tableHeaders"
            :items="backendTableData"
            :hide-default-footer="true"
            class="mx-8 mt-2"
          >
            <template v-slot:item.value="{ item }">
              {{
                item.dateTime === true ? formatDateTime(item.value) : item.value
              }}
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item key="jwtTab">
          <v-data-table
            :headers="tableHeaders"
            :items="jwtTableData"
            :hide-default-footer="true"
            class="mx-8 mt-2"
          >
            <template v-slot:item.value="{ item }">
              {{
                item.dateTime === true ? formatDateTime(item.value) : item.value
              }}
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item key="clientTab">
          <v-data-table
            :headers="tableHeaders"
            :items="clientTableData"
            :hide-default-footer="true"
            class="mx-8 mt-2"
          >
            <template v-slot:item.value="{ item }">
              {{
                item.dateTime === true ? formatDateTime(item.value) : item.value
              }}
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item key="telemetryTab">
          <TelemetryTab />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue'
import devService from '@/services/devPortal/devService.js'
import dateTimeFormatService from '@/services/formatters/dateTimeFormatService.js'
import TelemetryTab from '../../components/devPortal/TelemetryTab.vue'

export default {
  components: {
    SiteHeaderComponent,
    TelemetryTab
  },
  data: () => ({
    tab: null,
    loading: true,
    backendInformation: {},
    jwtInformation: {},
    clientInformation: {
      ipAddress: null,
      geolocation: {}
    },

    tableHeaders: [
      {
        text: 'Eigenschaft',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Wert',
        align: 'start',
        sortable: false,
        value: 'value'
      }
    ]
  }),
  computed: {
    backendTableData() {
      const bi = this.backendInformation
      return [
        { name: 'Backend Startzeitpunkt', value: bi.startTime, dateTime: true },
        { name: 'Backend Buildzeitpunkt', value: bi.buildTime, dateTime: true },
        { name: 'Systemzeit', value: bi.systemTime, dateTime: true },
        { name: 'Hostname', value: bi.hostname, dateTime: false },
        { name: 'PID (Prozess)', value: bi.pid, dateTime: false },
        {
          name: 'Host Username (Prozess)',
          value: bi.hostUsername,
          dateTime: false
        }
      ]
    },
    jwtTableData() {
      const jwt = this.jwtInformation
      return [
        { name: 'Token ausgestellt am', value: jwt.issuedAt, dateTime: true },
        { name: 'Token gültig bis', value: jwt.expiringAt, dateTime: true },
        {
          name: 'Dauer der Restgültigkeit',
          value:
            jwt.validDuration != null
              ? dateTimeFormatService.formatISODuration(jwt.validDuration)
              : '-',
          dateTime: false
        },
        { name: 'Benutzer', value: jwt.username, dateTime: false },
        {
          name: 'Token abgelaufen?',
          value: jwt.expired == true ? 'ja' : 'nein',
          dateTime: false
        }
      ]
    },
    clientTableData() {
      console.log(this.clientInformation)
      return [
        {
          name: 'Client IP-Adresse',
          value: this.clientInformation.ipAddress,
          dateTime: false
        },
        {
          name: 'Client ISP',
          value: this.clientInformation.geolocation.isp
        },
        {
          name: 'Client Standort',
          value:
            this.clientInformation.geolocation.zip +
            ' ' +
            this.clientInformation.geolocation.city +
            ', ' +
            this.clientInformation.geolocation.regionName +
            ', ' +
            this.clientInformation.geolocation.country +
            ', ' +
            this.clientInformation.geolocation.continent,
          dateTime: false
        },
        {
          name: 'Client Koordinaten',
          value:
            'N' +
            this.clientInformation.geolocation.lat +
            '° E' +
            this.clientInformation.geolocation.lon +
            '°'
        },
        {
          name: 'Proxy',
          value: this.clientInformation.geolocation.proxy
        },
        {
          name: 'Mobilgerät',
          value: this.clientInformation.geolocation.mobile
        },
        {
          name: 'Reverse DNS',
          value: this.clientInformation.geolocation.reverse
        }
      ]
    }
  },
  methods: {
    loadClientInformation() {
      this.loading = true
      this.loadClientInformations().finally(() => (this.loading = false))
    },
    async loadClientInformations() {
      await devService.getClientIpAddress().then(data => {
        this.clientInformation.ipAddress = data.ip
      })

      devService
        .getClientLocation(this.clientInformation.ipAddress)
        .then(data => {
          this.clientInformation.geolocation = data
        })
    },
    loadBackendInformation() {
      this.loading = true
      devService
        .getBackendInformation()
        .then(data => {
          this.backendInformation = data
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadJwtInformation() {
      this.loading = true
      devService.getJwtInformation().then(data => {
        this.jwtInformation = data
        this.loading = false
      })
    },
    formatDateTime(value) {
      return dateTimeFormatService.formatGermanDateTime(value)
    }
  },
  mounted() {
    this.loadBackendInformation()
    this.loadJwtInformation()
    this.loadClientInformation()
  }
}
</script>

<style></style>
