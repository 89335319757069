<template>
  <v-card class="px-3" :loading="loading">
    <v-card-title>
      {{ this.title }}
    </v-card-title>

    <v-card-subtitle class="text-caption">
      {{ this.subtitle }}
    </v-card-subtitle>

    <v-card-text class="body-1">
      {{ this.body }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({})
}
</script>
